var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materialboard nav_tab_wrap" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "nav_wrap",
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "备货总量看板", name: "stockuptotal" } },
            [
              _vm.isStockuptotal
                ? _c("stockuptotalboard", {
                    on: { activeObj: _vm.getActiveData }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "职业装常规备货看板", name: "businesswear" } },
            [
              _vm.isBusinesswear
                ? _c("businesswearboard", { attrs: { subName: _vm.subName } })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "零剪常规备货看板", name: "zeroshear" } },
            [_vm.isZeroshear ? _c("zeroshearboard") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "坯布和纱线看板", name: "greyclothandyarn" } },
            [_vm.isGreyclothandyarn ? _c("greyclothandyarnboard") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "非常规看板", name: "presaleOrder" } },
            [_vm.isPresaleOrder ? _c("presaleOrder") : _vm._e()],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }